.blog_card{
    border: 1px solid var(--border);
    margin: 20px;
}
.blog_card-content-btn{
    text-decoration: none;
    color: var(--hover-color) !important;
}

.blog_card_content-text{
    font-size: 1rem;
    color: var(--text-color) !important;
}

.blog_card_content-heading{
    color: var(--yellow);
    font-size: 1.3rem;
}