.main_container_course_detail_section {
    border: var(--border);
    margin: 50px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
}

.main_container_course_detail_section_content {
    margin: 20px 0;
    margin-top: 10px;
}

.main_container_course_detail_section_content_heading {
    padding-top: 10px !important;
    margin: 20px;
   
}
.course_img {
    width: 100%;
    height: 400px;
    border-radius: 8px;
}

.main_container_course_detail_section_content_desc-text {
    color: var(--text-color);
    font-size: 1rem;
    line-height: 2;
    margin: 20px;
}

.main_container_course_detail_section_content-btn{
    /* margin: 20px; */
    padding: 15px;
}

@media only screen and (max-width: 425px) {

    .main_container_course_detail_section{
        margin: 5px;
    }
    .main_container_course_detail_section_content_desc-text {
        font-size: .9rem;
    }
}