.about__section {
    padding-top: 40px;
    background: url("/public/images/bg.webp") no-repeat center;
    background-size: cover;
    margin-bottom: 20px;
    /* margin: 30px 0 !important; */
    /* background-color: var(--bgcolor); */
}

.about__main__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    grid-gap: .7rem;
}

.about__main__container__left {
    /* margin: auto 30px; */
    margin-right: 77px;
}

.about__main__container__left-text {
    color: var(--text-color);
    font-size: 1rem;
    line-height: 2;
}

.about__main__container__right {
    align-items: center;
    justify-content: center;
    display: flex;
}

/* .about__main__container__right-img {
    height: 500px;
    width: 500px;
    align-items: center;

} */
.about-img {
    height: 400px !important;
    width: 600px !important;
    overflow: auto;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    /* border-radius: 50%; */
}

@media only screen and (max-width: 768px) {
    .about__main__container {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        padding: 0;
        margin: 0%;
    }

    .about__main__container__left-text {
        font-size: 1rem;
    }

    .about__main__container__right-img {
        /* height: 300px; */
        /* width: 300px; */
        /* height: 385px;
        width: 385px; */
        padding: 0%;
        margin: 0%;

    }

    .about__main__container__left {
        margin: auto 2px;
    }

    .about-img {
        height: 350px !important;
        width: 350px !important;
        /* display: none !important; */
        /* border-radius: 50% !important; */
    }
}

@media only screen and (max-width: 425px) {
    .about__main__container__left {
        margin: 10px;
    }

}

@media only screen and (max-width: 425px) {
    .about__main__container {
        padding: 10px;
        margin: 0%;
    }

    .about__main__container__left {
        margin: 10px;
    }

    .about__main__container__left-text {

        font-size: 1rem;
    }

    .about__main__container__left-heading {
        font-size: 1.4rem;
    }

    .about__main__container__right-img {
        height: 300px;
        width: 300px;

    }

}

@media only screen and (max-width: 320px) {
    .about__main__container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        padding: 0;
        margin: 0%;
    }

    .about__main__container__right-img {
        height: 250px;
        width: 250px;

    }

}




.about-img {
    width: 800px;
    align-items: center;
}

@media only screen and (max-width: 768px) {

    .about-img {
        height: 300px !important;
        width: 300px !important;

    }

}

@media only screen and (max-width: 425px) {
    .about-img {
        height: 350px;
        width: 350px;

    }


}

@media only screen and (max-width: 320px) {

    .main__container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .about-img {
        height: 250px !important;
        width: 250px !important;

    }

}