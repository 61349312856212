.main_container_bread_section {
    min-height: 50vh;
    background: url("/public/images/bread2.jpg") no-repeat center;
    background-size: cover;
    background-position: center;
}
.main_container_bread_section_content{
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 30%;
    left: 10%;
    right: 10%;
}

/* linear-gradient(to bottom, rgba(139, 74, 0, 0.753), rgba(117, 19, 93, 0.73)), */