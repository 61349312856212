.form{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.form input, .input-form{
    margin: 10px 0;
    padding: 5px 9px;
    border-radius: 4px;
    border:  1px solid var(--hover-color);
    border:  1px solid var(--border);
}
input:focus
{
    border: 1px solid var(--yellow);
}

#contact{
    margin: 10px 0;
}
.btn-contact{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.contact-box-shodow{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: var(--box-shadow);
    margin: 30px 0;
    padding: 15px;
    background-color: var(--white);
    border-radius: 6px;
}

.feedback__form {
    display: flex;
    flex-direction: column;
}

.feedback__main__container__right-img{
    height: 500px;
    width: 500px;
    align-items: center;
}
.contact_submit{
    background-color: var(--yellow);
    padding: 10px 30px;
    border-radius: 6px;
    color: var(--white);
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    text-decoration: none;
    
}
.contact_submit:hover
{
    transition: .5s ease-in;
    background-color: var(--hover-color);
    color: var(--white);
    font-weight: bold;
    border: none;
}


@media only screen and (max-width: 768px) {

    
    .feedback__main__container__right-img
    {
        height: 300px;
        width: 300px;

    }

    .contact-box-shodow
    {
        width: 100%;
    }


}
