.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.pagination button {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  margin: 0 10px;
  /* border-radius: 6px; */
  cursor: pointer;
  transition: all 0.3s ease;
  background: transparent;
  color: var(--yellow);
  border: transparent ;
  /* border-color: var(--border) !important; */
}

.pagination button.active {
  font-weight: 900;
  border-color: transparent;
  background: var(--yellow);
  color: var(--hover-color);
}