.main_container_thank_you{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
}
.main_container_thank_you-content-h5{
    font-size: 1rem;
    color: var(--text-color);
}
.main_container_thank_you-content-button{
    display: flex;
    justify-content: center;
    text-align: center;
}
.main_container_thank_you-content-button-link{
    list-style: none;
    text-decoration: none;
}
