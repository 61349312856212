.main_container_notfound{
    text-align: center;
    margin: 80px 0;
    line-height: 1;
}    
.main_container_notfound > h1{
    font-size: 6rem;
}
.main_container_notfound > h1 > span{
    color: var(--yellow);
}
.main_container_notfound > h2{
    font-size: 4rem;
    color: var(--yellow);
}
.main_container_notfound > h5{
    color: var(--text-color);
    font-size: 1rem;
}
.main_container_notfound-link{
    color: var(--yellow);
}
.main_container_notfound-link:hover{
    color: var(--hover-color);
}