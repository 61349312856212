.main-navbar {
  /* background-color: transparent !important; */
  background-color: var(--yellow) !important;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999; 
}

.main_navbar{
  background-color: #1C96C5 !important;
}

.main-navbar.active {
  background-color: var(--yellow) !important;
}
.main-navbar.active:hover {
  color: var(--black) !important;
}
.nav{
  background-color: var(--yellow) !important;
}
.NavUl {
  margin: 0px 5px;
  padding: 3px 15px;
  text-decoration: none;
  color: var(--white);
  font-size: 1.1rem;
}
.NavUl:hover {
  font-weight: bold;
  /* color: var(--hover-color); */
  color: var(--yellow);
  border-bottom: 1px solid var(--yellow);
}
.NavUl.active:hover{
  color: var(--hover-color) !important;
  border-bottom: 1px solid var(--hover-color);
}
.navbar-brand {
  font-size: 1.4rem;
  color: var(--white) !important;
}

.navbar-brand:hover {
  color: var(--white) !important;
}

.activePage {
  font-weight: bold;
  border-bottom: 1px solid #1C96C5;
}

.navbar-toggler:focus, .navbar-collapse, .navbar-toggler {
  outline: none;
  text-decoration: none;
}

.navbar .navbar-toggler:focus {
  color: var(--white) !important;
}

.navbar .navbar-toggler:focus {
  color: var(--light-green) !important;
  outline: none !important;
  border: none !important;
}

/* Mobile Navbar Start */
.nav-img {
  cursor: pointer;
}

.mobile__navbar {
  font-size: 2rem;
  color: var(--white);
}

.mobile_version_offcanvas_nav {
  background-color: var(--white) !important;
}

.mobile_version_links {
  display: flex !important;
  flex-direction: column !important;
}

.mobile_navlink {
  color: var(--yellow);
  padding: 10px;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 700;
}

.mobile_version_offcanvas_nav-title {
  font-size: 1.8rem !important;
}

.mobile_version_offcanvas_nav-title:hover, .mobile_navlink:hover {
  border-bottom: 2px solid var(--yellow) !important;
  color: var(--yellow) !important;
}

.mobile__navbar {
  display: none !important;
  background: none !important;
  border: none !important;
  outline: none !important;
  cursor: pointer !important;
}

.btn:focus {
  border: none !important;
  outline: none !important;
}

.mobile_version_links {
  display: block;
  color: var(--black) !important;
}

.mobile__navbar:focus {
  outline: none !important;
  border: none !important;
}

.mobile_version_offcanvas_nav-title-link {
  color: var(--black);
  text-decoration: none;
}
.mobile_version_offcanvas_nav-title-link:hover {
  color: var(--black);
  text-decoration: none;
}

/* Mobile Navbar End */
@media screen and (max-width: 990px) {
  .mobile__navbar {
    display: block !important;

  }

  .NavUl {
    color: var(--black);
  }

  .NavUl:hover {
    color: var(--yellow);
    /* text-decoration: var(--hover-color) underline; */
  }

  .navbar-toggler {
    display: none !important;
  }
 

}
@media screen and (max-width: 768px) {
  .navbar-expand-md .navbar-collapse{
    display: none !important;
  }
}

@media screen and (max-width: 320px) {
  .navbar-brand {
    font-size: .9rem !important;
  }

}