.main_container_section {
    min-height: 100vh;
    background: url("/public/images/mainbg.jpg") no-repeat center;
    background-size: cover;
    background-position: center;
}
.main_container_home_content{
    display: flex;
    justify-content: center;
    position: absolute;
    top: 55%;
    left: 10%;
    right: 10%;
    text-align: center;
}
.main_container_home_upper_image{ 
    display: flex;
    justify-content: center;
    position: absolute;
    top: 21%;
    left: 10%;
    right: 10%;
    text-align: center;
} 

.main_container_home_upper_image-img{
    height: 250px;
    width: 450px;
}
.main_container_arabic-text {
    font-size: 4rem;
    color: var(--white);
}

.main_container_home-heading {
    font-size: 2rem;
    color: var(--white);
}

.main_container_home_content-seperater {
    border: 2px solid var(--yellow);
    width: 80px;
    display: flex;
    justify-content: center;
    margin: auto;
    border-radius: 3px;
}
.main_container_home_content-section-heading{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
}
.main_container_home_content-section-heading-span{
    font-size: 4rem;
    color: var(--white);
}
/* Home Media Query */
@media only screen and (max-width: 425px) {
    .main_container_arabic-text {
        font-size: 2rem;
    }

    .main_container_home-heading {
        font-size: 1.1rem;

    }
    .main_container_home_upper_image-img{
        width: 200px;
        height: 200px;
    }
}
