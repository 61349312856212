.main_container_home_vission_section{
    margin: 30px 0;
}
.main_container_home_vission_section_content{
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
.main_container_home_vission_section_vission, .main_container_home_vission_section_mission{
    margin: 20px 40px;
}
.main_container_home_vission_section-text{
    color: var(--text-color);
    font-size: 1rem;
    line-height: 2;
}


@media only screen and (max-width: 425px) {
    .main_container_home_vission_section_vission, .main_container_home_vission_section_mission {
        margin: 10px;
        display: grid;
        grid-gap: 0;
    }
    .main_container_home_vission_section-text{
        font-size: 1rem;
        color: var(--text-color);

    }

}

