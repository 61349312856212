@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400;500;600;700&family=Neucha&family=Noto+Naskh+Arabic:wght@400;500;600&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  /* font-family: 'Philosopher', sans-serif; */
  font-family: 'Baloo Bhai 2', cursive;
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

::selection {
  /* background-color: #147736; */
  background-color: var(--hover-color);

  color: #fff;
}

:root {
  --box-shadow: 0 .5rem 1.5rem #5D2B00;
  --green: #147736;
  --light-green: #32AA5C;
  --btn-color: #212121;
  --black: #000;
  --white: #fff;
  --text-color: #666;
  --sec-text-color: #B7B7B7;
  --yellow: #FF9822;
  --second-footer: #25182E;
  --hover-color: #5D2B00;
  --border: #B3B3B3; 
}


/*
font-family: 'Baloo Bhai 2', cursive;
font-family: 'Neucha', cursive;
font-family: 'Noto Naskh Arabic', serif;
font-family: 'Philosopher', sans-serif;

*/