.main__button
{
    display: flex;
    flex-direction: row;

}

.button
{
    /* margin-top: 15px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.button__btn {
    background-color: var(--yellow);
    padding: 10px 30px;
    border-radius: 6px;
    color: var(--white);
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
    text-decoration: none;
    
}
.button__btn:hover
{
    transition: .5s ease-in;
    background-color: var(--hover-color);
    color: var(--white);
    font-weight: bold;
    border: none;
}