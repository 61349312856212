ul {
    margin: 0px;
    padding: 0px;
}

.footer-section {
    background-color: var(--hover-color);
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 20px;
}

.footer-cta {
    border-bottom: 1px solid var(--white);
}

.single-cta i {
    color: #ff5e14;
    font-size: 30px;
    float: left;
    margin-top: 8px;
}

.cta-text {
    padding-left: 15px;
    display: inline-block;
}

.cta-text h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
}

.cta-text span {
    color: var(--white);
    font-size: 15px;
}
.cta-text-span-a{
    color: var(--white);
    text-decoration: none;
}
.cta-text-span-a:hover{
    color: var(--white);
    text-decoration: underline;
}
.footer-content {
    position: relative;
    z-index: 2;
}

.footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
}

.footer-logo {
    margin-bottom: 30px;
}

.footer-logo img {
    max-width: 200px;
}

.footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: var(--white);
    line-height: 28px;
}

.footer-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
}

.footer-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
}

.footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}
.footer-widget-heading h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
}

.footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background-color: var(--white);
}

.footer-widget ul li {
    display: inline-block;
    float: left;
    width: 50%;
    margin-bottom: 12px;
}

.footer-widget ul li a:hover {
    color: var(--white);
    text-decoration: underline;    
}

.footer-widget ul li a {
    color: var(--white);
    text-transform: capitalize;
    list-style: none;
    text-decoration: none;
}

.subscribe-form {
    position: relative;
    overflow: hidden;
}

.subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    /* background: #2E2E2E; */
    border: 1px solid #2E2E2E;
    color: #fff;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    background: var(--light-green);
    padding: 13px 20px;
    border: 1px solid var(--white);
    top: 0;
}

.subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
}

.copyright-area {
    background: #202020;
    padding: 25px 0;
    text-align: center;
}

.copyright-text p {
    margin: 0;
    font-size: 14px;
    color: var(--text-color);
}

.copyright-text p a {
    color: var(--yellow);
}

.footer-menu li {
    display: inline-block;
    margin-left: 20px;
}

.footer-menu li:hover a {
    color: var(--white);
}

.footer-menu li a {
    font-size: 14px;
    color: #878787;
}

.footer-logo-h1 {
    color: var(--white);
}