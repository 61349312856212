.main_container_info-flex{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: 20px 40px;
}

.info-flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.info-icons{
    font-size: 2rem;
    color: var(--yellow);
}


