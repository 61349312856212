.main_container_choose_section_content{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: 10px 40px;
    grid-gap: 2rem;
    /* border: 1px solid red; */

}
.main_container_choose_section_left-text {
    color: var(--text-color);
    font-size: 1rem;
    line-height: 2;
}
@media only screen and (max-width: 768px) {
    .main_container_choose_section_content{
        margin: 10px;
    }

}


