.course_card{
    border: 1px solid var(--border);
    margin: 20px;
}
.card-content-btn{
    text-decoration: none;
    color: var(--hover-color);
}

.course_card_content-text{
    font-size: 1rem;
    color: var(--text-color);
}

.course_card_content-heading{
    color: var(--yellow);
    font-size: 1.6rem;
}