.main_container_course_card{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    place-items: center;
}
.main_tabs{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 70%;
    border-radius: 8px;
    background-color: var(--white) !important;
    background-color: var(--yellow) !important;
}

.nav-link{
    color: var(--white) !important;
}
.nav-link .active{
    color: var(--black) !important;
}
.nav-link:hover {
    color: var(--hover-color) !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color: var(--black) !important;

}