.services{
    margin: 40px;
    margin-top: 80px !important;
}
.box-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.box {
    flex: 1 1 30rem;
    border-radius: .5rem;
    padding: 1rem;
    text-align: center;
}

.box-icon {
    padding: 1rem;
    font-size: 5rem;
    color: var(--yellow);
}
.services_subheading{
    text-align: center;
}
.services_heading{
    text-align: center;
}
.services .box-container .box h3 {
    font-size: 1.8rem;
    color: #333;
}

.services .box-container .box p {
    font-size: 1rem;
    color: var(--text-color);
    padding: 1rem 0;
}

.services .box-container .box:hover {
    /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, .1); */
    box-shadow: var(--box-shadow);
}

@media only screen and (max-width: 425px) {
    .services{
        margin: 10px;
    }

}

