.main_container_blog_detail_section {
    border: var(--border);
    margin: 50px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
}

.main_container_blog_detail_section_content {
    margin: 20px 0;
    margin-top: 10px;
}

.main_container_blog_detail_section_content_heading {
    padding-top: 10px !important;
    margin: 20px;
    text-align: center !important;
}
.blog_img {
    width: 100%;
    height: 400px;
    border-radius: 8px;
}
.main_container_blog_detail_section_content_desc{
    margin: 20px;
}
.main_container_blog_detail_section_content_desc-text {
    color: var(--text-color);
    font-size: 1rem;
    line-height: 1.5;
   
}
.main_container_blog_detail_section_content_tags{
    display: flex;
    justify-content: flex-end;
    margin: 4px;
    flex-wrap: wrap;
}
.main_container_blog_detail_section_content_tags-p{
    font-size: .7rem;
    color: var(--white);
    background-color: var(--yellow);
    padding: 5px;
    border-radius: 5px;
    margin: 2px;
}
.main_container_blog_detail_section_content_date{
    display: flex;
    justify-content: flex-end;
    margin: 8px;
    font-size: 1rem;
    color: var(--yellow);
    font-weight: 800;
}
/* .main_container_blog_detail_section_content_desc-text > img{
    height: 200px !important;
    width: 200px !important;
}

.imageBlog{
    height: 200px !important;
    width: 200px !important;
} */
@media only screen and (max-width: 425px) {

    .main_container_blog_detail_section{
        margin: 5px;
    }
    .main_container_blog_detail_section_content_desc-text {
        font-size: .9rem;
    }
    
}