.main_container_loader{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.main_container_loader-margin{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
}